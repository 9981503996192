import React, { useEffect, useState } from 'react';
import './css/Compra.css';
import Header from '../components/Header/header.jsx';
import Slide from '../components/Card/ImageSlider.jsx';
import { useWindowScroll } from '@uidotdev/usehooks';
import ListaProductosDisponibles from '../components/Card/ListaProductosDisponibles.jsx'; 
import Cart from '../components/Card/Cart.jsx';
import Footer from '../components/Footer/Footer.jsx'; 

const Compra = () => {
  const [{ y }] = useWindowScroll();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (y > 700) {
      setScrollPosition(1);
    } else {
      setScrollPosition(0);
    }
  }, [y]);

  return (
    <div className="contenedorCompra">
      <Header scrollPosition={scrollPosition} className="fixed-header" />
      <div className="cuerpoCompra">
        <Slide tipo="Tienda" /> 
        <div className='flex flex-col w-full items-center'>
          <div className='mb-0 w-full'>
            <Cart />
          </div>
          <ListaProductosDisponibles /> {/* Aquí se mostrarán los productos disponibles */}
        </div>
      </div>
      <div className='outer-div-Footer'>
        <Footer />
      </div>
    </div>
  );
};

export default Compra;